import i18n from 'i18next';

import authClient from 'utils/authClient';
import notify from 'utils/notify';

export const checkFetchStatus = (status: number) => {
  if (status === 401 || status === 403) {
    let msg = 'Something went wrong. Please, log in again.';
    if (status === 401) msg = 'Session has expired. Please, log in again.';

    notify({
      copy: i18n.t(msg),
      type: 'error',
      showNotificationAfterReload: true,
    });
    sessionStorage.removeItem('login');
    authClient.logout({
      redirectUri: `${window.location.origin}/login`,
    });
  }
};

export const checkIfTheUserShouldBeLoggedOut = checkFetchStatus;
