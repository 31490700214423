export const appVersion = '1.15.0';
export const maxAttachmentSizeInMB = 5;

/* eslint-disable no-underscore-dangle */
export const baseURL: string = window?.__env__?.API ?? '/api/mobile_groups';

// user roles
export const roleOperator = 'operator';
export const roleAuditor = 'audytor';
export const roleSystemAdministrator = 'administrator_globalny';
export const roleLocalAdministrator = 'administrator_lokalny';
export const roleServiceManager = 'manager_uslugi';
export const roleReadOnlyOperator = 'operator_read_only';
// these roles have access system
export const rolesWithWebAccess = [
  roleOperator,
  roleAuditor,
  roleSystemAdministrator,
  roleLocalAdministrator,
  roleReadOnlyOperator,
];

// parameters
export const formDataLimit = 10;
export const toastAutoCloseTime = 7000;

export const timeFormatRegex = /^([0-1]?\d|2[0-3]):[0-5]\d$/;

// time
export const timelinePositionInterval = 1 * 1_000; // 1s
export const getGroupsTimeInterval = 10 * 60 * 1_000; // 10min
export const errorDelayTime = 3 * 1_000; // 3s

export const defaultDateFormat = 'dd.MM.yyyy';
export const defaultDatePlaceholder = 'dd.mm.yyyy';
export const defaultErrorTextFromDate = 'Select the date';
export const fieldRequired = 'Field required';
export const invalidTimeFormat = 'Invalid time format';
export const defaultErrorNotificationDisable =
  "Give your consent to receive notifications. Notifications don't work until you agree.";
export const somethingWentWrong = 'Oops. Something went wrong.';

// form action types
export const OPTION_PHONE_NUMBER = 'option_phone_number';
export const SAVE_PHONE_NUMBER = 'save_phone_number';
export const SET_START_DATE = 'setStartDate';
export const SET_END_DATE = 'setEndDate';
export const SET_SEARCH = 'setSearch';
export const SET_SCHEDULE_MODE = 'setScheduleMode';
export const SAVE = 'save';
export const ERROR = 'error';
export const RESET_FORM = 'resetForm';
export const RESET_ERRORS = 'resetErrors';
export const RESET_VALUE = 'resetValue';
export const SET_SHOW_FILTERS = 'setShowFilters';
export const SAVE_FILTERS = 'saveFilters';
export const REMOVE_FILTER = 'removeFilter';
export const CLEAR_ALL_FILTERS = 'clearAllFilters';
export const SAVE_ENTITY_DATA = 'saveEntityData';
export const SAVE_TASK_DATA = 'SAVE_TASK_DATA';
