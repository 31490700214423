import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { ReactComponent as GidLogo } from 'assets/svg/logo-gid.svg';
import { ButtonPrimary } from 'components';
import LoginPageWrapper from './LoginPageWrapper';
import {
  GidLogoContainer,
  LoginButtonContainer,
  LoginPanel,
  Logo,
  LogoContainer,
  LogoCaption,
} from './Login.style';
import authClient from 'utils/authClient';

type tLoginPage = {
  pathname: string;
};

const LoginPage = ({ pathname }: tLoginPage) => {
  const { t } = useTranslation();

  const currentLocationState: string = pathname === '/login' ? '/home' : pathname;

  const login = useCallback(() => {
    authClient.login();
    sessionStorage.setItem('login', 'true');
  }, []);

  if (authClient.authenticated) return <Navigate to={currentLocationState as string} />;

  return (
    <LoginPageWrapper>
      <LoginPanel>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <LoginButtonContainer>
          <ButtonPrimary handleClick={login} icon='login' text='Login with GID' />
        </LoginButtonContainer>
        <GidLogoContainer>
          <LogoCaption>{t('Created by')}:</LogoCaption>
          <GidLogo />
        </GidLogoContainer>
      </LoginPanel>
    </LoginPageWrapper>
  );
};

export default Sentry.withProfiler(LoginPage, { name: 'LoginPage' });
