import { useEffect, ReactNode } from 'react';
import { Wrapper, Content } from './Modal.style';

type tModal = {
  visible: boolean;
  children: ReactNode;
  boxMaxWidth?: string;
  background?: string;
  backdrop?: string;
};

const Modal = ({ visible = false, children = null, boxMaxWidth, background, backdrop }: tModal) => {
  useEffect(() => {
    if (visible) document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [visible]);

  if (!visible) return null;

  return (
    <Wrapper $background={background} $backdrop={backdrop}>
      <Content $boxMaxWidth={boxMaxWidth}>{children}</Content>
    </Wrapper>
  );
};

export default Modal;
