import React, { StrictMode, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import 'react-toastify/dist/ReactToastify.css';

import { Icon, KeycloakProvider, ErrorBoundary, ButtonCancel, ButtonPrimary } from 'components';
import { colorRadicalRed } from 'styles/constants';
import { getAppInsights } from 'services/telemetry';
import TelemetryProvider from 'utils/telemetry-provider';

import { IconButtonContainer } from 'components/Maps/GroupSchedule.style';
import AppRouter from './AppRouter';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { Actions } from 'components/ConfirmDelete/ConfirmDelete.style';
import authClient from 'utils/authClient';
import { ModalContainer } from 'components/Modal/Modal.style';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { t } = useTranslation();
  const [showDataSecurityModal, setShowDataSecurityModal] = useState<boolean>(false);
  const isAuth = JSON.parse(sessionStorage.getItem('login') ?? 'false');
  const acceptDataSecurity = JSON.parse(sessionStorage.getItem('acceptSecurityData') ?? 'false');

  useEffect(() => {
    if (isAuth && !acceptDataSecurity) {
      setShowDataSecurityModal(true);
    }
  }, []);

  const logout = () => {
    authClient.logout({
      redirectUri: `${window.location.origin}/home`,
    });
    sessionStorage.removeItem('login');
    sessionStorage.removeItem('acceptSecurityData');
  };

  const hideModalSecurityData = () => {
    setShowDataSecurityModal(false);
    sessionStorage.setItem('acceptSecurityData', 'true');
  };

  return (
    <KeycloakProvider>
      <StrictMode>
        <TelemetryProvider
          // eslint-disable-next-line no-underscore-dangle
          instrumentationKey={window?.__env__?.INSTRUMENTATION_KEY ?? null}
          after={getAppInsights}
        >
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <ToastContainer
                autoClose={false}
                closeButton={({ closeToast }) => (
                  <IconButtonContainer
                    onClick={(e: React.MouseEvent<HTMLElement>) => closeToast(e)}
                    style={{ top: '12px', right: '12px' }}
                  >
                    <Icon
                      iconName='close'
                      width='1.25rem'
                      height='1.25rem'
                      fill={colorRadicalRed}
                    />
                  </IconButtonContainer>
                )}
                closeOnClick={false}
                draggable={false}
                hideProgressBar
                newestOnTop
                position='bottom-right'
                rtl={false}
              />
              <Modal
                visible={showDataSecurityModal}
                background='#72849ECC 0% 0% no-repeat padding-box'
                backdrop='blur(30px)'
              >
                <ModalContainer>
                  <h3>{t('Security data')}</h3>
                  <p>
                    {t(
                      'The data in the application constitutes a business secret and is confidential.',
                    )}
                  </p>
                  <Actions>
                    <ButtonCancel onClick={logout}>{t('Logout')}</ButtonCancel>
                    <ButtonPrimary handleClick={hideModalSecurityData} text={t('Accept')} />
                  </Actions>
                </ModalContainer>
              </Modal>
              <AppRouter />
            </QueryClientProvider>
          </ErrorBoundary>
        </TelemetryProvider>
      </StrictMode>
    </KeycloakProvider>
  );
};

export default Sentry.withProfiler(App, { name: 'App' });
