import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clearQueryCache } from 'utils/queryClient';

import { ReactComponent as GidLogo } from 'assets/svg/logo-gid.svg';
import { appVersion } from 'utils/constants';
import { useSidebar } from 'contexts/sidebar';
import { colorRadicalRed } from 'styles/constants';
import { Icon, Tooltip } from 'components';
import { IconButton } from 'styles/button';
import { LogoCaption } from 'views/Login/Login.style';
import Menu from './Menu/Menu';
import {
  BottomContainer,
  GidLogoContainer,
  LogoutButton,
  LogoutContainer,
  LogoutCopy,
  MenuCloseButtonContainer,
  SidebarContainer,
  TopSection,
  VersionCaption,
  Collapse,
  BottomMeta,
} from './Sidebar.style';
import notify from 'utils/notify';
import authClient from 'utils/authClient';
import { useQueryClient } from '@tanstack/react-query';

type tSidebar = {
  // eslint-disable-next-line no-unused-vars
  setShowRwdMenu: (val: boolean) => void;
  showRwdMenu: boolean;
};

const Sidebar: FC<tSidebar> = ({ setShowRwdMenu, showRwdMenu }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { wideMenu, setWideMenu } = useSidebar();

  const logout = () => {
    authClient.logout({
      redirectUri: `${window.location.origin}/home`,
    });
    sessionStorage.removeItem('login');
    sessionStorage.removeItem('acceptSecurityData');
  };

  const collapse = () => setWideMenu(!wideMenu);

  const clearQueryCacheHandler = () => {
    try {
      clearQueryCache(queryClient);
      notify({ copy: t('The query cache has been cleared'), type: 'success' });
    } catch (error) {
      notify({ copy: t('An error occurred while deleting the cache'), type: 'error' });
    }
  };

  return (
    <SidebarContainer $showRwdMenu={showRwdMenu} $wideMenu={wideMenu}>
      {showRwdMenu && (
        <MenuCloseButtonContainer>
          <IconButton onClick={() => setShowRwdMenu(!showRwdMenu)}>
            <Icon iconName='close' />
          </IconButton>
        </MenuCloseButtonContainer>
      )}
      <Collapse type='button' onClick={collapse} $wideMenu={wideMenu}>
        <Icon iconName='menuOpen' />
      </Collapse>
      <TopSection>
        <Menu closeMenu={() => setShowRwdMenu(false)} />
      </TopSection>
      <BottomContainer>
        <LogoutContainer>
          <Tooltip
            show={wideMenu}
            tipId='logout-tooltip'
            content={<LogoutCopy>{t('Logout')}</LogoutCopy>}
            direction='right'
          >
            <LogoutButton onClick={logout}>
              <Icon fill={colorRadicalRed} iconName='power' />
              {!wideMenu && <LogoutCopy>{t('Logout')}</LogoutCopy>}
            </LogoutButton>
          </Tooltip>
        </LogoutContainer>
        {!wideMenu && (
          <GidLogoContainer>
            <LogoCaption>{t('Created by')}:</LogoCaption>
            <GidLogo style={{ height: '44px', width: '100px' }} />
          </GidLogoContainer>
        )}
        <BottomMeta $wideMenu={wideMenu}>
          <Tooltip tipId='clear-cache-tooltip' content={<>{t('Clean cache')}</>} direction='right'>
            <IconButton onClick={clearQueryCacheHandler}>
              <Icon iconName='clearAll' />
            </IconButton>
          </Tooltip>
          <VersionCaption>v{appVersion}</VersionCaption>
        </BottomMeta>
      </BottomContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
